<template>
  <a-layout class="layout">
    <a-layout-header>
      <div>深圳市智聚云创科技有限公司</div>

    </a-layout-header>
    <a-layout-content style="padding: 0 50px">
      <div :style="{ background: '#fff', padding: '24px', minHeight: '280px' }">
        阿斯顿业务管理系统项目入口:&nbsp;<a href="http://www.szzjyc.cn:8090/login">进入</a>
      </div>
    </a-layout-content>
    <a-layout-footer style="text-align: center">
      <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2023110130号</a>
    </a-layout-footer>
  </a-layout>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'MainPage',
  setup() {
    return {

    };
  },
});
</script>
<style>
.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
}
#components-layout-demo-top .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}
.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

[data-theme='dark'] .site-layout-content {
  background: #141414;
}
</style>
